import React from "react";
import PropTypes from "prop-types";
import { Layout } from "../../components";
import ContactView from "../../page-components/contact";

const Component = props => {
  return (
    <Layout>
      <ContactView />
    </Layout>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
