// @flow
import React from "react";
import PropTypes from "prop-types";

type Type= {
  email:string,
  phone?:string,
  address?:string
}

const Component = ({phone,address,email}:Type) => {
  const mailTo = `mailto:${email}`
  return (
    <div className="contact-info">
      {address && <p><i className="fas fa-home"/> address: {address}</p>}
      {phone &&<p><i className="fas fa-phone"/> phone: {phone}</p>}
      {email && <p><i className="fas fa-envelope"/> email: <a href={mailTo}
                                                       target="_blank">{email}</a></p>}
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
