// @flow
import React from 'react'
import PropTypes from "prop-types";
import { Info, Social, ContactForm } from "./components";
const EMAIL = "info@femiakinsanyaartcollection.com";
type Type = {
  address?: string,
  phone?: string,
  email?: string,
  subtitle?: string,
  title?: string,
  src?: string,
};

const Component = ({ address, phone, email,subtitle,title,src }: Type) => {
  return (
    <section id="contact-section">
      <div className="contact-section-inner tt-wrap">
        <div className="split-box">
          <div className="container-fluid">
            <div className="row">
              <div className="row-lg-height full-height-vh">
                  <div
                    className="col-lg-6 col-lg-height col-lg-middle bg-image background-fifty-percent"
                    style={{backgroundImage:`url(${src || '#'})`}}
                   >
                  <div className="cover" />
                  <div className="split-box-content text-left no-padding-left no-padding-right">
                    <div className="contact-info-wrap">
                      <Info address={address} email={email} phone={phone} />
                      <Social />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-lg-height col-lg-middle no-padding">
                  <div className="split-box-content">
                    <ContactForm action={'contact/success'} title={title} subtitle={subtitle} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Component.propTypes = {

};

Component.defaultProps = {
  title: "Contact Us",
  subtitle: EMAIL,
  email: EMAIL,
  src:''
};

export default Component;
