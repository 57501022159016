import React from "react";
import { Tools } from "../../../components";

import PropTypes from "prop-types";

const Component = props => {
  return <Tools.SocialButtons contact={null} classes={"btn-default"} />;
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
