import React from "react";
import PropTypes from "prop-types";
import {Forms} from '../../../components'
const FORM_NAME = 'faac-contact-form';

const Component = ({ title, subtitle, action }) => {
  return (
   <Forms.BaseForm action={action}  name={FORM_NAME}>
     <div className="contact-form-inner text-left">
       <div className="contact-form-info">
         <div className="tt-heading">
           <div className="tt-heading-inner">
             <h1 className="tt-heading-title">{title}</h1>
             <hr className="hr-short" />
           </div>
         </div>
         <div className="margin-top-30">
           <p>{subtitle}</p>
         </div>
       </div>
       <div className="row">
         <div className="col-lg-6">
           <div className="form-group">
             <input
               type="text"
               className="form-control"
               name="name"
               placeholder="Your Name"
               required
             />
           </div>
         </div>
         <div className="col-lg-6">
           <div className="form-group">
             <input
               type="email"
               className="form-control"
               name="email"
               placeholder="Your Email"
               required
             />
           </div>
         </div>
       </div>
       <div className="row">
         <div className="col-lg-12">
           <div className="form-group">
             <input
               type="text"
               className="form-control"
               name="subject"
               placeholder="Subject"
               required
             />
           </div>
         </div>
       </div>
       <div className="row">
         <div className="col-lg-12">
           <div className="form-group">
             <select className="form-control" name="option" required>
               <option value="none">
                 Select an option
               </option>
               <option value="feedback"> Feedback</option>
               <option value="enquiry">Enquiry</option>
               <option value="comment">Comment</option>
               <option value="Other">Other</option>
             </select>
           </div>
         </div>
       </div>
       <div className="row">
         <div className="col-lg-12">
           <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                rows="4"
                placeholder="Your Message"
                required
              />
           </div>
         </div>
       </div>

       <div className="small text-gray">
         <em>* All fields are required!</em>
       </div>
     </div>
     <div className="row">
       <div className="col-lg-12">
         <button
           type="submit"
           className="btn btn-primary btn-lg margin-top-40"
         >
           Send Message
         </button>
       </div>
     </div>
   </Forms.BaseForm>
  );
};
Component.propTypes = {};
Component.defaultProps = {};

export default Component;
